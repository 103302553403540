<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="none" v-model="searchParam.plantCd" @datachange="getList" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="대상년도"
            type="year"
            default="today"
            name="uninjuryYear"
            v-model="searchParam.uninjuryYear"
            @datachange="getList"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="false"
            label="인원기준일"
            name="standardDate"
            v-model="data.standardDate">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="false"
            label="평균인원(생산직접부서)"
            name="avgCnt"
            v-model="data.avgCnt">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-btn v-if="editable" label="신규년도 추가" icon="add" @btnClicked="addYear" class="uninjury-addyear" />
        </div>
      </template>
    </c-search-box>
    <!-- 부서별 무재해현황 -->
    <c-table
      ref="table"
      :title="searchParam.uninjuryYear + '년도 무재해 추진운동'"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="data.deptUninjurys"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      rowKey="deptCd"
      selection="single"
      @table-data-change="tableDataChange"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && data.deptUninjurys.length > 0" label="무재해 달성등록" color="green" icon="autorenew" @btnClicked="endUninjury" />
          <c-btn v-if="editable && data.deptUninjurys.length > 0" label="등록사고 적용" color="red" icon="check" @btnClicked="changeAccident" />
          <!-- <c-btn v-if="editable && data.deptUninjurys.length > 0" label="부서 추가" icon="add" @btnClicked="addDept" /> -->
          <c-btn label="LBLSAVE" icon="save" @btnClicked="saveDept" v-if="editable && data.deptUninjurys.length > 0" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'successFlag'">
          <q-chip
            v-if="chgTargetStatus(props.row)"
            outline square
            color="red"
            text-color="white">
            목표초과
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'uninjury-manage',
  data() {
    return {
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        uninjuryYear: '',
      },
      data: {
        plantCd: '',  // 사업장코드
        uninjuryYear: '',  // 무사고 년도
        standardDate: '',  // 인원기준일
        avgCnt: '',  // 기준일기준 부서평균인원(가중인원대상부서)
        deptUninjurys: [], // 부서별 무재해현황
      },
      grid: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
        ],
        columns: [
          {
            setHeader: true,
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            style: 'width:140px',
            align: 'center',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            style: 'width:140px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'deptCnt',
            field: 'deptCnt',
            label: '인원',
            style: 'width:70px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'standardDay',
            field: 'standardDay',
            label: '기준일',
            style: 'width:70px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'weightedCnt',
            field: 'weightedCnt',
            label: '가중인원',
            align: 'right',
            type: 'cost',
            // type: 'number',
            // disableTarget: 'increaseFlag',
            // disableCon: 'Y',
            style: 'width:80px',
            sortable: false,
          },
          {
            required: true,
            name: 'multipleCnt',
            field: 'multipleCnt',
            label: '배수',
            align: 'right',
            type: 'number',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'targetDay',
            field: 'targetDay',
            label: '목표일수',
            align: 'right',
            type: 'cost',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'targetExpectedDt',
            field: 'targetExpectedDt',
            label: '달성예정일',
            align: 'center',
            // type: 'date',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'recentAccidentDt',
            field: 'recentAccidentDt',
            label: '최근사고일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
          },
          {
            required: true,
            name: 'startDt',
            field: 'startDt',
            label: '개시일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'attainDay',
            field: 'attainDay',
            label: '현재달성일수',
            align: 'right',
            type: 'cost',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'successFlag',
            field: 'successFlag',
            label: '상태',
            align: 'center',
            type: 'custom',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'recentAccidentUserName',
            field: 'recentAccidentUserName',
            label: '최근사고자',
            align: 'center',
            type: 'user',
            deptCd: 'recentAccidentUserDeptCd',
            deptName: 'recentAccidentUserDeptName',
            targetDeptCd: 'deptCd',
            userId: 'recentAccidentUser',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            style: 'width:160px',
            sortable: false,
          },
          {
            name: 'realAccidentInfo',
            field: 'realAccidentInfo',
            label: '최근사고일 이후 등록사고정보',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'link',
          },
        ],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      updateMstUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.uninjury.list.url;
      this.updateMstUrl = transactionConfig.sai.uninjury.update.url
      this.getList();
    },
    getList(yearInfo) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      if (yearInfo) {
        if (yearInfo.plantCd !== undefined) {
          this.searchParam.plantCd = yearInfo.plantCd;
          this.searchParam.uninjuryYear = yearInfo.uninjuryYear;
        }
      }
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.data = _result.data;
        if (yearInfo) {
          if (yearInfo.plantCd !== undefined) {
            this.$_.forEach(this.data.deptUninjurys, item => {
              this.setTargetDate({row: item})
              this.$set(item, 'editFlag', 'U')
            })
            window.getApp.$emit("ALERT", {
              title: "LBLGUIDE", // 안내
              message: "신규년도 추가 후 저장을 한번 클릭하셔야 정상적으로 적용됩니다.", // 선택된 항목이 없습니다.
              type: "info", // success / info / warning / error
            });
          }
        }
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row) {
      this.popupOptions.title = "사고 상세"; // 사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopupAccident;
    },
    closePopupAccident() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    addYear() {
      this.popupOptions.target = () => import(`${"./uninjuryStandard.vue"}`);
      this.popupOptions.title = '신규년도 무재해 부서추가';
      this.popupOptions.param = {
        plantCd: this.searchParam.plantCd,
      };
      this.popupOptions.width = '800px';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(yearInfo) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList(yearInfo);
    },
    addDept() {

    },
    saveDept() {
      if (this.$comm.validTable(this.grid.columns, this.data.deptUninjurys)) {
        let saveData = this.data.deptUninjurys.filter( x => {
          return x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId
          item.chgUserId = this.$store.getters.user.userId
        });

        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.updateMstUrl;
              this.$http.type = 'PUT';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    changeAccident() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if (!selectData[0].iimAccidentId) {
          window.getApp.$emit("ALERT", {
            title: "LBLGUIDE", // 안내
            message: "최근사고일 이후에 시스템에 등록된 사고정보가 없습니다.", // 선택된 항목이 없습니다.
            type: "warning", // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '배수가 1로 초기화 됩니다.\r\n최근사고정보로 업데이트 하시겠습니까?\r\n(저장을 클릭하셔야 시스템에 저장이 됩니다.)', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$set(selectData[0], 'recentAccidentId', selectData[0].iimAccidentId) 
              this.$set(selectData[0], 'recentAccidentUser', selectData[0].accidentUserId)
              this.$set(selectData[0], 'recentAccidentDt', selectData[0].accidentDate)
              this.$set(selectData[0], 'recentAccidentUserName', selectData[0].accidentUserName)

              this.$set(selectData[0], 'startDt', this.$comm.getAddDate(selectData[0].recentAccidentDt, 1))  // 개시일
              this.$set(selectData[0], 'multipleCnt', 1)
              this.$set(selectData[0], 'editFlag', 'U')
              this.setTargetDate({row: selectData[0]})
              this.setAttainDay({row: selectData[0]})
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    endUninjury() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if (this.$comm.getDatediff(this.$comm.moment(selectData[0].targetExpectedDt)) < 0) {
          window.getApp.$emit("ALERT", {
            title: "LBLGUIDE", // 안내
            message: "달성되지 않은 부서입니다.", // 선택된 항목이 없습니다.
            type: "warning", // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '무재해 달성처리 후 개시일을 초기화 합니다.\r\n진행하시겠습니까?', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.forEach(selectData, item => {
                this.$set(item, 'history', {
                  plantCd: item.plantCd,  // 사업장코드
                  uninjuryYear: item.uninjuryYear,  // 무사고 년도
                  deptCd: item.deptCd,  // 부서코드
                  startDt: item.startDt,  // 개시일
                  endDt: item.targetExpectedDt,  // 종료일
                  deptCnt: item.deptCnt,  // 부서인원
                  targetDay: item.targetDay,  // 목표일수
                  paymentFlag: 'N',  // 지급여부
                  regUserId: this.$store.getters.user.userId,  // 작성자
                  chgUserId: this.$store.getters.user.userId,  // 수정자
                });

                item.editFlag = 'C'
                this.$set(item, 'multipleCnt', Number(item.multipleCnt) + 1)  // 배수증가
                this.$set(item, 'startDt', this.$comm.getAddDate(item.targetExpectedDt, 1))  // 달성예정일

                let _multipleday = Number(item.standardDay) + Number(item.weightedCnt) // 1차 목표일수
                let _targetday = Number(_multipleday) * Number(item.multipleCnt);

                let _targetDate = Math.ceil(_targetday/10) * 10;
                this.$set(item, 'targetDay', _targetDate)  // 목표일수 (1차 목표일수 x 배수)
                this.$set(item, 'targetExpectedDt', this.$comm.getAddDate(item.startDt, Number(item.targetDay)))  // 달성예정일
                this.$set(item, 'attainDay', this.$comm.getDatediff(this.$comm.moment(item.startDt))) 
              })
              this.$http.url = transactionConfig.sai.uninjury.history.url;
              this.$http.type = 'POST';
              this.$http.param = selectData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '무재해 달성이력에서 확인 가능합니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }

    },
    tableDataChange(props, col) {
      if (col.name === 'deptCnt' || col.name === 'multipleCnt') { // 부서인원/배수 수정 시
        this.setTargetDate(props)
      } else if (col.name === 'recentAccidentDt') { // 최근사고일 수정 시
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '최근사고일 변경 시 배수가 1로 초기화 됩니다.\r\n수정하시겠습니까?', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$set(props.row, 'startDt', this.$comm.getAddDate(props.row.recentAccidentDt, 1))  // 개시일
            this.$set(props.row, 'multipleCnt', 1) // 배수 초기화
            this.setTargetDate(props)
            this.setAttainDay(props)
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else if (col.name === 'startDt') { // 개시일 변경
        this.$set(props.row, 'targetExpectedDt', this.$comm.getAddDate(props.row.startDt, Number(props.row.targetDay)))  // 달성예정일
        this.$set(props.row, 'attainDay', this.$comm.getDatediff(this.$comm.moment(props.row.startDt))) 
      }
    },
    setTargetDate(props) {
      let filterData = this.$_.filter(this.data.deptUninjurys, {increaseFlag: 'Y'})
      let _deptCntTotal = 0;
      this.$_.forEach(filterData, item => {
        _deptCntTotal += Number(item.deptCnt)
      })
      let _avgCnt = Math.ceil(_deptCntTotal / filterData.length);
      this.$set(this.data,'avgCnt',_avgCnt);  // 평균인원
      this.$set(props.row, 'weightedCnt', Number(_avgCnt) - Number(props.row.deptCnt))  // 가중인원
      let _multipleday = Number(props.row.standardDay) + (Number(_avgCnt) - Number(props.row.deptCnt)) // 1차 목표일수
      let _targetday = Number(_multipleday) * Number(props.row.multipleCnt);
      let _targetDate = Math.ceil(_targetday/10) * 10;
      this.$set(props.row, 'targetDay', _targetDate)  // 목표일수 (1차 목표일수 x 배수)
      this.$set(props.row, 'targetExpectedDt', this.$comm.getAddDate(props.row.startDt, Number(_targetDate)))  // 달성예정일
    },
    setAttainDay(props) { // 현재달성일수
      this.$set(props.row, 'attainDay', this.$comm.getDatediff(this.$comm.moment(props.row.startDt))) 
    },
    chgTargetStatus(row) { // 무재해 달성여부
      if (this.$comm.getDatediff(this.$comm.moment(row.targetExpectedDt)) >= 0) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
<style scoped>
.uninjury-addyear {
  margin-top: 15px;
}
</style>